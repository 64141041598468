var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"domainAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"domain-add-modal","no-close-on-backdrop":"","no-close-on-esc":"","centered":"","header-bg-variant":"primary"},on:{"hidden":_vm.handleHide,"close":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.productData.id ? '編輯' : '新增')+" 網域商品 ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}],null,true)},[_c('div',[_c('b-form',{on:{"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"商品種類","label-for":"type"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":[
                { value: 'domain_new', label: '網域購買託管' },
                { value: 'domain_trans', label: '網域攜入' },
                { value: 'domain_continue', label: '網域續約' },
                { value: 'domain_free', label: '免費網址' } ],"reduce":function (option) { return option.value; },"clearable":false},on:{"input":_vm.changeDomainTypeProduct},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
              var search = ref.search;
              var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.productData.type),callback:function ($$v) {_vm.$set(_vm.productData, "type", $$v)},expression:"productData.type"}})],1),(!_vm.isLoadingBusy)?_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"7"}},[_c('b-form-group',{attrs:{"label-for":"prefix"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 網域前綴 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"網域前綴","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"prefix","autofocus":"","trim":"","placeholder":"請輸入網域前綴"},model:{value:(_vm.productData.prefix),callback:function ($$v) {_vm.$set(_vm.productData, "prefix", $$v)},expression:"productData.prefix"}}),(errors[0])?_c('small',{staticClass:"ml-25 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"網域後綴","label-for":"product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 網域後綴 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"select-type",attrs:{"options":_vm.productOptions,"clearable":false,"input-id":"id","placeholder":"請選擇商品後綴","label":"suffix","disabled":!_vm.productData.product_info},on:{"input":_vm.changeDomainProduct},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
              var search = ref.search;
              var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有 "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_vm._e()]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.suffix)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.suffix)+" ")])]}}],null,true),model:{value:(_vm.productData.product_info),callback:function ($$v) {_vm.$set(_vm.productData, "product_info", $$v)},expression:"productData.product_info"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"服務週期","label-for":"quantity"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 服務週期 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),(_vm.productData.type === 'domain_trans')?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],attrs:{"title":("轉移費用 $ " + (parseInt(_vm.productData.product_info.transfer, 10).toLocaleString()) + " NTD")}},[_c('b-img',{attrs:{"src":_vm.$store.state.app.themeImages.infoImg,"width":"22","alt":"金額設定說明"}})],1):_vm._e()])]},proxy:true}],null,true)},[_c('v-select',{attrs:{"options":_vm.productData.product && _vm.productData.product_info ? _vm.productData.product_info.price : [],"clearable":false,"label":"quantity","placeholder":"請選擇服務週期","disabled":!_vm.productData.product},on:{"input":function (value) { return _vm.productData.quantity = value.quantity; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
              var search = ref.search;
              var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無價格資料")])])]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.quantity)+" 年 - $ "+_vm._s(parseInt(option.price, 10).toLocaleString())+" "),_c('small',[_vm._v("NTD")])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.quantity)+" 年 - $ "+_vm._s(parseInt(option.price, 10).toLocaleString())+" "),_c('small',[_vm._v("NTD")])])]}}],null,true),model:{value:(_vm.productData.quantity_info),callback:function ($$v) {_vm.$set(_vm.productData, "quantity_info", $$v)},expression:"productData.quantity_info"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"備註","label-for":"comment"}},[_c('b-form-textarea',{attrs:{"id":"comment","trim":"","placeholder":"請輸入備註"},model:{value:(_vm.productData.comment),callback:function ($$v) {_vm.$set(_vm.productData, "comment", $$v)},expression:"productData.comment"}})],1)],1)],1):_c('div',{staticClass:"loading-area"},[_c('b-img',{attrs:{"src":_vm.$store.state.app.themeImages.loadingImg,"rounded":"","height":"60","width":"60"}})],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }