<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="商品SSID"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.product ? productData.product : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="網域前綴"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.prefix ? productData.info.prefix : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="網域後綴"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.suffix ? productData.info.suffix : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="服務週期"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.quantity ? `${productData.info.quantity}年` : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        v-if="productData.type === 'domain_trans'"
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="轉移密碼"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.transkey ? productData.info.transkey : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="備註"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.comment ? productData.comment : '---' }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import { useOrderSetting } from '../../../useOrder'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      ui,
    } = useOrderSetting()

    return {
      ui,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
