<template>
  <div>
    <section class="invoice-add-wrapper">
      <b-row class="invoice-preview">
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- 公司資料及截止日期 -->
            <b-card-body class="invoice-padding pb-0">
              <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0 mb-1"
                >
                  <div
                    v-if="branchData.id"
                    class="invoice-logo cursor-pointer"
                    @click="submitBranchChoice"
                  >
                    <b-img
                      :src="branchData.icon ? branchData.icon : '/dashboard/admin/images/logo/logo-text01.png'"
                      alt="logo"
                      height="40"
                    />

                  </div>

                  <div
                    v-else
                    class="invoice-blank-area py-3"
                    @click="submitBranchChoice"
                  >
                    <span class="invoice-step">
                      1
                    </span>請選擇分站
                  </div>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div class="mt-2">
                    <div class="invoice-date-wrapper detail-title">
                      <p class="invoice-date-title">
                        訂單編號：
                      </p>
                      <p class="invoice-date">
                        <small class="text-muted">尚未建立</small>
                      </p>
                    </div>

                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        開立日期：
                      </p>
                      <p class="invoice-date">
                        {{ moment().format('YYYY/MM/DD HH:mm') }}
                      </p>
                    </div>

                    <div class="invoice-date-wrapper invoice-state">
                      <p class="invoice-date-title">
                        訂單建立：
                      </p>
                      <p class="invoice-date">
                        {{ $store.state.app.userData.name }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <hr class="invoice-spacing">

            <!-- 買方資料及付款詳情 -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0 mb-1"
                >
                  <h5 class="mb-1">
                    買受人：
                  </h5>

                  <div v-if="customerData.id">
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        姓名：
                      </p>
                      <p class="invoice-date">
                        <b-link
                          v-if="customerData.id"
                          @click="submitCustomerChoice"
                        >
                          <div class="d-flex align-items-center">
                            {{ customerData.name }}{{ customerData.family_name }}
                            <div
                              class="actions-link-btn ml-25"
                            >
                              <b-img
                                v-b-tooltip.hover.focus.v-secondary
                                title="變更會員"
                                src="/dashboard/admin/images/table/edit.svg"
                                class="actions-link-btn-image"
                                rounded
                              />
                            </div>
                          </div>
                        </b-link>
                      </p>
                    </div>

                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        電子郵件：
                      </p>
                      <p class="invoice-date">
                        {{ customerData.id ? customerData.account : '---' }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-else
                    class="invoice-blank-area py-1"
                    @click="submitCustomerChoice"
                  >
                    <span class="invoice-step">
                      2
                    </span>請選擇會員
                  </div>
                </b-col>

                <!-- Col： 付款詳情 -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h5 class="mb-1 detail-title">
                      付款詳情：
                    </h5>

                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        付款金額：
                      </p>
                      <p class="invoice-date">
                        <span
                          v-if="orderData.price !== null"
                          class="d-flex align-items-center"
                        >
                          ${{ parseInt(calcProductTotalPrice(), 10).toLocaleString() }} NTD
                          <div
                            v-if="orderPriceSetting"
                            v-b-tooltip.hover.v-secondary
                            title="當前價格為手動調整價格"
                          >
                            <b-img
                              :src="$store.state.app.themeImages.infoImg"
                              width="22"
                              alt="金額說明"
                            />
                          </div>
                        </span>
                        <span
                          v-else
                          class="text-muted"
                        >尚未設置</span>
                      </p>
                    </div>

                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        繳費方式：
                      </p>
                      <div>
                        <p
                          v-if="paymentType === 'system'"
                          class="invoice-date"
                        >
                          手動開單
                        </p>

                        <p
                          v-else-if="paymentType === 'payment'"
                          class="invoice-date"
                        >
                          自選模式
                        </p>

                        <p
                          v-else
                          class="invoice-date text-muted"
                        >
                          尚未設置
                        </p>
                      </div>
                    </div>

                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        繳費代碼：
                      </p>
                      <p class="invoice-date">
                        ---
                      </p>
                    </div>

                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        繳費時間：
                      </p>
                      <p class="invoice-date">
                        ---
                      </p>
                    </div>

                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- 購買明細 -->
            <b-table
              responsive
              :items="orderData.product"
              :fields="[
                { label: '編號', key: 'index' },
                { label: '種類', key: 'product_type' },
                { label: '商品', key: 'product_info' },
                { label: '數量', key: 'quantity' },
                { label: '單位', key: 'unit' },
                { label: '售價', key: 'price' },
                { label: '狀態', key: 'state' },
                { label: '動作', key: 'show_details' },
              ]"
              show-empty
              details-td-class="p-0"
              :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <!-- 忙碌中 -->
              <template #table-busy>
                <b-skeleton-table
                  :rows="5"
                  :columns="tableColumnsFilter.length"
                  :table-props="{ bordered: true, striped: true }"
                />
              </template>

              <!-- 查無資料 -->
              <template #empty>
                <div class="text-center my-2">
                  <div
                    class="invoice-blank-area py-5"
                    @click="submitProductChoice"
                  >
                    <span class="invoice-step">3</span>加入商品
                  </div>
                </div>
              </template>

              <!-- 欄位: 序號 -->
              <template #cell(index)="data">
                <div class="table-col">
                  {{ data.index + 1 }}
                </div>
              </template>

              <!-- 欄位: 種類 -->
              <template #cell(product_info)="data">
                <div class="table-col">
                  <div class="cursor-pointer d-flex align-items-center justify-content-between">
                    <b-link
                      class="font-weight-bold mb-0"
                      @click="data.toggleDetails"
                    >
                      <div class="mb-0">
                        <div class="item-description server-name">
                          {{ ui.productType[data.item.type.toLowerCase()] }}
                        </div>
                        <small class="text-muted">
                          <div v-if="data.item.product_type === 'domain'">
                            {{ data.item.info.prefix }}{{ data.item.info.suffix }}
                          </div>

                          <div v-if="data.item.product_type === 'vps'" />

                          <div v-if="data.item.product_type === 'host'" />
                        </small>
                      </div>
                    </b-link>
                  </div>
                </div>
              </template>

              <!-- 欄位: 商品 -->
              <template #cell(product_type)="data">
                <div class="table-col">
                  {{ ui.product[data.item.product_type] }}
                </div>
              </template>

              <!-- 欄位: 數量 -->
              <template #cell(quantity)="data">
                <div class="table-col">
                  <div v-if="data.item.product_type === 'domain'">
                    1
                  </div>

                  <div v-if="data.item.product_type === 'vps'" />

                  <div v-if="data.item.product_type === 'host'" />
                </div>
              </template>

              <!-- 欄位: 單位 -->
              <template #cell(unit)="data">
                <div class="table-col">
                  <div v-if="data.item.product_type === 'domain'">
                    {{ data.item.info.quantity }}年
                  </div>

                  <div v-if="data.item.product_type === 'vps'" />

                  <div v-if="data.item.product_type === 'host'" />
                </div>
              </template>

              <!-- 欄位: 售價 -->
              <template #cell(price)="data">
                <div class="table-col text-nowrap">
                  $ {{ parseInt(data.item.price, 10).toLocaleString() }}
                </div>
              </template>

              <!-- 欄位: 狀態 -->
              <template #cell(state)>
                <div class="table-col">
                  ---
                </div>
              </template>

              <template #cell(show_details)="data">
                <div class="table-col d-flex mb-50">
                  <!-- @click="onSubmitEdit(data.item)" -->
                  <div
                    class="actions-link-btn mr-25"
                    @click="submitProductEdit(data.item, data.index)"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="編輯"
                      src="/dashboard/admin/images/table/edit.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>

                  <div
                    class="actions-link-btn mr-25"
                    @click="submitProductRemove(data.item, data.index)"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="移除"
                      src="/dashboard/admin/images/table/delete.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                    <!-- :src="require('@/assets/images/pages/ui/blue-line/close.svg')" -->
                  </div>

                  <div
                    class="actions-link-btn mr-25"
                    @click="data.toggleDetails"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      :title="`${data.detailsShowing ? '隱藏' : '顯示'}詳情`"
                      src="/dashboard/admin/images/table/menu.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </div>
              </template>

              <!-- 欄位: 詳細 -->
              <template #row-details="data">
                <div class="table-toggle-detail border-top p-1 animate__animated animate__fadeIn">
                  <b-card class="m-0">
                    <h5>詳細內容</h5>

                    <component-product-domain-detail
                      v-if="data.item.product_type === 'domain'"
                      :product-data="data.item"
                    />
                  </b-card>
                </div>
              </template>
            </b-table>

            <!-- 新增商品 -->
            <div
              v-if="orderData.product.length > 0"
              class="text-center"
            >
              <button
                class="cart-button"
                @click="submitProductChoice"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="14"
                  style="margin-top: -3px;"
                />
                新增商品
              </button>
            </div>

            <!-- 折扣/總金額 -->
            <b-card-body class="invoice-padding pb-0 mt-3">
              <b-row>
                <!-- 折扣 -->
                <b-col
                  cols="12"
                  lg="7"
                  class="mt-md-0 d-flex align-items-center"
                  order="1"
                  order-md="1"
                />

                <!-- 金額計算 -->
                <b-col
                  cols="12"
                  lg="5"
                  class="mt-md-6 d-flex justify-content-end"
                  order="2"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper detail-title">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        小計：
                      </p>
                      <p class="invoice-total-amount">
                        ${{ parseInt(calcProductSubTotalPrice(orderData.product), 10).toLocaleString() }} NTD
                        <!-- {{ invoiceData.total.toLocaleString() }} -->
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        折扣：
                      </p>
                      <div class="invoice-total-amount">
                        ---
                      </div>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-nowrap">
                        總金額：
                      </p>
                      <div>
                        <!-- <p class="invoice-total-amount">
                          ${{ parseInt(calcProductTotalPrice(), 10).toLocaleString() }} NTD
                        </p> -->

                        <div class="d-flex align-items-center">
                          ${{ parseInt(calcProductTotalPrice(), 10).toLocaleString() }} NTD
                          <div
                            v-if="orderData.price !== null"
                            class="actions-link-btn ml-25"
                            @click="submitPriceSettingChoice"
                          >
                            <b-img
                              v-b-tooltip.hover.focus.v-secondary
                              title="變更總額"
                              src="/dashboard/admin/images/table/edit.svg"
                              class="actions-link-btn-image"
                              rounded
                            />
                          </div>
                        </div>

                        <!-- <div
                          v-else
                          class="invoice-blank-area py-50 px-1 w-100"
                          @click="submitPriceSettingChoice"
                        >
                          <span class="invoice-step">4</span>金額設定
                        </div> -->
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- 備註 -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">
                <!-- v-model="invoiceData.note" -->
                <span class="font-weight-bold">備註: </span>
                <b-form-textarea
                  v-model="orderData.content"
                  placeholder="感謝您的支持及購買，如有任何疑問，請隨時聯絡我們。"
                />
              </span>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions mt-md-0 mt-2"
        >
          <b-card>

            <div v-if="orderData.price !== null">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="headline-primary mb-0">
                  金額設定
                </h4>

                <div
                  v-if="orderData.price !== null"
                  class="actions-link-btn ml-25"
                  @click="submitPriceSettingChoice"
                >
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="變更總額"
                    src="/dashboard/admin/images/table/edit.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>

              <div class="payment-type-radio-group mt-1">
                <label
                  class="payment-type-radio"
                  :class="{ 'active': orderPriceSetting === 0 }"
                  @click="() => {
                    customizeType = 0
                    callbackPriceSettingData()
                  }"
                >
                  <div class="radio-type">
                    <p>依商品價格總額
                      <small v-if="orderPriceSetting === 0"> ( $ {{ parseInt(calcProductSubTotalPrice(orderData.product), 10).toLocaleString() }} NTD )</small>
                    </p>
                  </div>

                  <input
                    type="radio"
                    name="price"
                    :value="0"
                  >
                </label>

                <label
                  class="payment-type-radio"
                  :class="{ 'active': orderPriceSetting === 1 }"
                  @click="() => {
                    customizeType = 1
                    callbackPriceSettingData()
                  }"
                >
                  <!-- submitPriceSettingChoice -->
                  <!-- selected -->
                  <div class="radio-type">
                    <div>
                      <p>自訂 <small v-if="orderPriceSetting === 1"> ( $ {{ parseInt(customizePrice, 10).toLocaleString() }} NTD )</small></p>
                    </div>
                  </div>

                  <input
                    type="radio"
                    name="price"
                    :value="1"
                  >
                </label>
              </div>
            </div>

            <div
              v-else
              class="invoice-blank-area py-50 px-1 w-100"
              @click="submitPriceSettingChoice"
            >
              <span class="invoice-step">4</span>金額設定
            </div>
          </b-card>

          <b-card>
            <div>
              <div v-if="paymentShow">
                <h4 class="headline-primary">
                  付款模式
                </h4>

                <div class="payment-type-radio-group">
                  <label
                    class="payment-type-radio"
                    :class="{ 'active': paymentType === 'system' }"
                  >
                    <div class="radio-type">
                      <img
                        src="/dashboard/admin/images/sideIcon/order-list.svg"
                        height="32"
                        width="32"
                      >
                      <div>
                        <p>手動開單</p>
                        <small>不透過金流</small>
                      </div>
                    </div>

                    <input
                      v-model="paymentType"
                      type="radio"
                      name="payment"
                      value="system"
                    >
                  </label>

                  <label
                    class="payment-type-radio"
                    :class="{ 'active': paymentType === 'payment' }"
                  >
                    <div class="radio-type">
                      <img
                        :src="require('@/assets/images/pages/payment/store.svg')"
                        height="32"
                        width="32"
                      >
                      <div>
                        <p>自選模式</p>
                        <small>會員自選付款方式</small>
                      </div>
                    </div>

                    <input
                      v-model="paymentType"
                      type="radio"
                      name="payment"
                      value="payment"
                    >
                  </label>
                </div>
              </div>

              <div
                v-else
                class="invoice-blank-area py-1"
                @click="() => paymentShow = true"
              >
                <span class="invoice-step">5</span>請選擇付款方式
              </div>
            </div>
          </b-card>

          <div
            class="complete-button"
            :class="{ 'complete-button-disable': isInfoBusy }"
            @click="submitOrderCreate"
          >
            <span
              v-if="isInfoBusy"
              class="d-flex align-items-center justify-content-center"
            >
              <b-spinner
                small
                class="mr-50"
              />
              處理中
            </span>
            <span v-else>建立訂單</span>
            <span class="button-border" />
          </div>

        </b-col>
      </b-row>

      <!-- <div>orderData: {{ orderData }}</div>
      <div>branchData: {{ branchData }}</div>
      <div>customerData: {{ customerData }}</div> -->
    </section>

    <!-- 新增商品 -->
    <b-modal
      id="add-product-modal"
      no-close-on-esc
      no-close-on-backdrop
      ok-title="確認"
      header-bg-variant="primary"
      ok-only
      centered
      hide-footer
      size="lg"
      class="add-product-modal"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          新增商品
        </h4>
      </template>

      <div class="p-1">
        <div class="d-lg-none d-block">
          <b-row>
            <b-col
              cols="12"
              lg="4"
              md="6"
            >
              <div
                class="link-card text-center"
                @click="submitProductAdd('domain')"
              >
                <b-img
                  src="/dashboard/admin/images/sideIcon/domain.svg"
                  class="link-card-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  網域
                </h5>
              </div>
            </b-col>

            <b-col
              cols="12"
              lg="4"
              md="6"
            >
              <div
                class="link-card text-center"
                @click="submitProductAdd('vps')"
              >
                <b-img
                  src="/dashboard/admin/images/sideIcon/tools-1.svg"
                  class="link-card-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  虛擬主機
                </h5>
              </div>

            </b-col>

            <b-col
              cols="12"
              lg="4"
              md="6"
            >
              <div
                class="link-card text-center"
                @click="submitProductAdd('host')"
              >
                <b-img
                  src="/dashboard/admin/images/sideIcon/tools-2.svg"
                  class="link-card-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  實體主機
                </h5>
              </div>

            </b-col>
          </b-row>
        </div>

        <div class="d-none d-lg-block">
          <b-row>
            <!-- 網域 -->
            <b-col
              cols="12"
              sm="6"
            >
              <div class="button-mode mb-2">
                <div class="button-type">
                  <b-img
                    src="/dashboard/admin/images/sideIcon/domain.svg"
                    class="link-card-image"
                    rounded
                  />

                  <h5 class="text-body-heading mb-0">
                    網域
                  </h5>
                </div>
                <div class="big-button">
                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="購買新網域並託管"
                    class="small-button"
                    @click="submitProductAdd('domain', 'domain_new')"
                  >
                    <div class="button-small-type">

                      <h5 class="text-body-small-heading mb-0">
                        購買託管
                      </h5>
                    </div>
                  </span>

                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="將網域轉移到新註冊商"
                    class="small-button"
                    @click="submitProductAdd('domain', 'domain_trans')"
                  >
                    <div class="button-small-type">
                      <h5 class="text-body-small-heading mb-0">
                        攜入
                      </h5>
                    </div>
                  </span>

                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="延長網域的有效期"
                    class="small-button"
                    @click="submitProductAdd('domain', 'domain_continue')"
                  >
                    <div class="button-small-type">
                      <h5 class="text-body-small-heading mb-0">
                        續約
                      </h5>
                    </div>
                  </span>

                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="虛擬主機綁定專用"
                    class="small-button"
                    @click="submitProductAdd('domain', 'domain_free')"
                  >
                    <div class="button-small-type">

                      <h5 class="text-body-small-heading mb-0">
                        免費
                      </h5>
                    </div>
                  </span>
                </div>
              </div>
            </b-col>

            <!-- 虛擬主機 -->
            <b-col
              cols="12"
              sm="6"
            >
              <div
                class="link-card text-center"
                @click="submitProductAdd('vps')"
              >
                <b-img
                  src="/dashboard/admin/images/sideIcon/tools-1.svg"
                  class="link-card-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  虛擬主機
                </h5>
              </div>

            </b-col>

            <!-- 實體主機 -->
            <b-col
              cols="12"
              sm="6"
            >
              <div
                class="link-card text-center"
                @click="submitProductAdd('host')"
              >
                <b-img
                  src="/dashboard/admin/images/sideIcon/tools-2.svg"
                  class="link-card-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  實體主機
                </h5>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <!-- 訂單商品 -->
    <div v-if="selectProduct">
      <component
        :is="`component-product-${selectProduct.product_type}`"
        ref="componentProduct"
        :product-item="selectProduct"
        class="animate__animated animate__fadeIn"
        @callback-product-data="callbackProductData"
      />
    </div>

    <admin-branch-modal
      ref="adminBranchExplorerModal"
      branch-explorer-id="adminBranchExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacBranchExplorer"
    />

    <admin-customer-modal
      ref="adminCustomerExplorerModal"
      branch-explorer-id="adminCustomerExplorerModal"
      :filter-branch="branchData.id"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacCustomerExplorer"
    />

    <b-modal
      id="price-setting-modal"
      no-close-on-esc
      no-close-on-backdrop
      ok-title="確認"
      header-bg-variant="primary"
      ok-only
      centered
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          金額設定
        </h4>
      </template>

      <div>
        <div class="price-setting-radio-wrapper">
          <label class="label">
            <input
              v-model="customizeType"
              :value="0"
              name="price-setting-radio"
              class="radio-input"
              type="radio"
            >
            <div class="radio-design" />
            <div class="label-text">依商品價格總額(${{ parseInt(calcProductSubTotalPrice(orderData.product), 10).toLocaleString() }} NTD)</div>
          </label>
          <label class="label">
            <input
              v-model="customizeType"
              :value="1"
              name="price-setting-radio"
              class="radio-input"
              type="radio"
            >
            <div class="radio-design" />
            <div class="label-text">自訂</div>
          </label>
        </div>

        <div
          v-if="customizeType === 1"
          class="mt-50 ml-50"
        >
          <b-form-input
            v-model="customizePrice"
            trim
            placeholder="請輸入自訂訂單金額"
            type="number"
          />
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            @click="callbackPriceSettingData"
          >
            確認
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody, BImg, BTable, BLink, BSpinner, BFormTextarea, VBTooltip, BModal, BFormGroup,
  BButton, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import router from '@/router'
import store from '@/store'
import useStoreModule from '../useStoreModule'
import { useOrderView, useOrderSetting } from '../useOrder'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import AdminBranchModal from '@/layouts/components/Modal/admin-branch-modal/branchModal.vue'
import AdminCustomerModal from '@/layouts/components/Modal/admin-customer-modal/customerModal.vue'
import ComponentProductDomain from './components/domain/DomainAdd.vue'
import ComponentProductDomainDetail from './components/domain/DomainDetails.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BButton,
    BCardBody,
    BTable,
    BSpinner,
    BLink,
    BModal,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    vSelect,
    // ComponentDomain,

    AdminBranchModal,
    AdminCustomerModal,
    ComponentProductDomain,
    ComponentProductDomainDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      selectProduct: null,
      paymentShow: false,
      paymentType: null,
      customizePrice: '',
      customizeType: 0,
    }
  },
  methods: {
    moment,
    // (觸發)分站選擇
    submitBranchChoice() {
      if (this.isInfoBusy) return
      this.$refs.adminBranchExplorerModal.getData(this.branchData)
    },

    // (回傳)所選擇的分站資料
    callbacBranchExplorer(item) {
      if (this.branchData.id && this.branchData.id === item.id) return
      if (this.customerData.id && this.customerData.id !== item.id) {
        this.useSwalAlertWarning('分站變更', '分站變更將重新設定會員資料及付款方式，確定要變更分站嗎？')
          .then(result => {
            if (result.value) {
              this.customerData = JSON.parse(JSON.stringify(this.blankCustomerData))
              this.branchData.icon = item.icon
              this.branchData.id = item.id
              this.branchData.name = item.name
            }
          })
        return
      }
      this.branchData.icon = item.icon
      this.branchData.id = item.id
      this.branchData.name = item.name
    },

    // (觸發)會員選擇
    submitCustomerChoice() {
      if (this.isInfoBusy) return
      if (!this.branchData.id) {
        this.useAlertToast(false, '分站尚未設定')
        return
      }
      this.$refs.adminCustomerExplorerModal.getData(this.customerData)
    },

    // (回傳)所選擇的會員資料
    callbacCustomerExplorer(item) {
      this.customerData.id = item.id
      this.customerData.ssid = item.ssid
      this.customerData.name = item.name
      this.customerData.family_name = item.family_name
      this.customerData.account = item.account
      this.customerData.image = item.image
    },

    // (觸發)選擇商品種類
    submitProductChoice() {
      if (this.isInfoBusy) return
      this.$bvModal.show('add-product-modal')
    },

    // (觸發)選擇商品種類
    submitProductAdd(productType, type) {
      switch (productType) {
        case 'domain': {
          const blankProductData = {
            id: null,
            type: 'domain_new',
            product: null,
            product_type: productType,
            price: 0,
            comment: null,
            info: {
              prefix: null,
              quantity: 1,
              suffix: null,
            },
          }

          if (type) blankProductData.type = type
          this.selectProduct = blankProductData
          // this.orderData.product.push(blankProductData)
          setTimeout(() => { this.$refs.componentProduct.getData() }, 200)
          this.$bvModal.hide('add-product-modal')
          break
        }
        case 'vps':
          this.useAlertToast(false, '商品尚未啟用')
          break
        case 'host':
          this.useAlertToast(false, '商品尚未啟用')
          break
        default:
          break
      }
    },

    // (觸發)編輯商品
    submitProductEdit(product, index) {
      if (this.isInfoBusy) return
      switch (product.product_type) {
        case 'domain': {
          const blankProductData = {
            ...product,
            id: index,
          }
          this.selectProduct = blankProductData
          setTimeout(() => { this.$refs.componentProduct.getData() }, 200)
          this.$bvModal.hide('add-product-modal')
          break
        }
        case 'vps':
          this.useAlertToast(false, '商品尚未啟用')
          break
        case 'host':
          this.useAlertToast(false, '商品尚未啟用')
          break
        default:
          break
      }
    },

    // (觸發)移除商品
    submitProductRemove(product, index) {
      if (this.isInfoBusy) return
      let resolveProductName = this.ui.productType[product.type.toLowerCase()]
      if (product.product_type === 'domain') {
        resolveProductName = `${resolveProductName} (${product.info.prefix}${product.info.suffix})`
      }
      this.useSwalAlertWarning('移除商品', `你確定要移除訂單商品 ${resolveProductName || `第${index + 1}項`} 嗎?`)
        .then(result => {
          if (result.value) {
            this.orderData.product.splice(index, 1)
            this.useSwalAlertCenter(true, '移除成功!', '商品已移除')
          }
        })
    },

    // (回傳)設定商品資料
    callbackProductData(productData) {
      // this.orderData.product.push(productData)
      if (productData.id === null) {
        this.orderData.product.push(productData)
      } else {
        this.orderData.product.splice(productData.id, 1, productData)
      }
    },

    // (觸發)金額設定
    submitPriceSettingChoice() {
      if (this.isInfoBusy) return
      if (this.orderData.product.length <= 0) {
        this.useAlertToast(false, '商品列表不得為空')
        return
      }
      this.customizeType = JSON.parse(JSON.stringify(this.orderPriceSetting))
      if (this.orderPriceSetting === 0) {
        const subTotal = this.calcProductSubTotalPrice(this.orderData.product)
        this.customizePrice = subTotal
      }
      if (this.orderPriceSetting === 1) {
        this.customizePrice = JSON.parse(JSON.stringify(this.orderData.price))
      }
      this.$bvModal.show('price-setting-modal')
    },

    // (回傳)金額設定
    callbackPriceSettingData() {
      if (this.customizeType === null) {
        return
      }

      if (this.customizeType === 0) {
        this.orderData.price = this.calcProductSubTotalPrice(this.orderData.product)
        this.orderPriceSetting = 0
      } else {
        if (this.customizePrice === '') {
          this.useAlertToast(false, '自訂金額不得為空')
          return
        }
        this.orderData.price = parseInt(this.customizePrice, 10)
        this.orderPriceSetting = 1

        if (this.orderData.price === 0) {
          this.paymentType = 'system'
        }
      }

      this.$bvModal.hide('price-setting-modal')
    },

    // (計算)購物車金額相加
    calcProductSubTotalPrice(productData) {
      const filteredData = productData.filter(item => item.price !== null)
      const totalPrice = filteredData.reduce((total, item) => total + parseInt(item.price, 10), 0)
      return totalPrice
    },

    // (計算)購物車總金額
    calcProductTotalPrice() {
      if (this.orderPriceSetting === 1) return this.orderData.price

      const subTotal = this.calcProductSubTotalPrice(this.orderData.product)
      return subTotal
    },

    // (觸發)訂單建立
    submitOrderCreate() {
      if (!this.branchData.id) {
        this.useAlertToast(false, '分站尚未設定')
        return
      }

      if (!this.customerData.id) {
        this.useAlertToast(false, '會員尚未設定')
        return
      }

      if (this.orderData.product.length <= 0) {
        this.useAlertToast(false, '商品不得為空')
        return
      }

      if (this.orderData.price === null) {
        this.useAlertToast(false, '訂單金額尚未設定')
        return
      }

      if (this.paymentType === null) {
        this.useAlertToast(false, '付款方式尚未設定')
        return
      }

      if (this.paymentType === 'payment' && this.calcProductTotalPrice() === 0) {
        this.useAlertToast(false, '訂單金額為0，不得選擇該付款模式')
        return
      }

      this.useSwalAlertInfo('建立訂單', '確定要建立訂單嗎?')
        .then(result => {
          if (result.value) {
            this.isInfoBusy = true

            const resolveData = {
              customer: this.customerData.ssid,
              is_admin_pay: this.paymentType === 'system' ? 1 : 0,
              price: this.calcProductTotalPrice(),
              content: this.orderData.content,
              product: this.orderData.product,
            }

            this.setOrderCreate(resolveData)
              .then(response => {
                this.$swal({
                  icon: 'success',
                  title: '訂單建立成功',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                  },
                  confirmButtonText: '前往詳情',
                  cancelButtonText: '關閉',
                  buttonsStyling: false,
                })
                  .then(resultSuccess => {
                    if (resultSuccess.value) {
                      const { data } = response.data
                      this.$router.push({
                        name: 'admin-order-view',
                        params: {
                          id: data.id,
                        },
                      })
                    } else {
                      this.isInfoBusy = false
                      this.resetOrderInfo()
                    }
                  })
              })
              .catch(() => {
                this.useAlertToast(false, '訂單建立失敗')
                this.isInfoBusy = false
              })
          }
        })
    },
  },
  setup() {
    // 註冊模組
    const ORDER_ADMIN_STORE_MODULE_NAME = 'admin-order'

    if (!store.hasModule(ORDER_ADMIN_STORE_MODULE_NAME)) store.registerModule(ORDER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(ORDER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(ORDER_ADMIN_STORE_MODULE_NAME)
    })

    const {
      isInfoBusy,
      blankProductInfoData,
      blankProductData,
    } = useOrderView()

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      setOrderCreate,
    } = useOrderSetting()

    const {
      useAlertToast,
    } = useAlert()

    // const branchList = ref([])

    const orderData = ref(null)
    const branchData = ref(null)
    const customerData = ref(null)
    const orderPriceSetting = ref(0)

    const blankCustomerData = {
      id: null,
      ssid: null,
      image: null,
      account: null,
      name: null,
      family_name: null,
      branch_id: null,
    }

    const blankBranchData = {
      id: null,
      name: null,
      icon: null,
    }

    const blankOrderInfo = {
      price: null,
      discount: 0,
      product: [],
      content: '',
    }

    const resetOrderInfo = () => {
      orderData.value = JSON.parse(JSON.stringify(blankOrderInfo))
      branchData.value = JSON.parse(JSON.stringify(blankBranchData))
      customerData.value = JSON.parse(JSON.stringify(blankCustomerData))
    }

    resetOrderInfo()

    return {
      ui,
      useAlertToast,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,

      isInfoBusy,
      setOrderCreate,
      // ----------------------------------
      orderData,
      orderPriceSetting,
      branchData,
      customerData,
      resetOrderInfo,
      blankBranchData,
      blankCustomerData,
      blankProductInfoData,
      blankProductData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
</style>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");

.dark-layout {
  .complete-button {
    &::before {
      background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(8, 77, 126, 0.42) 100%),rgba(47, 255, 255, 0.24);
      box-shadow: inset 0 0 12px rgba(151, 200, 255, 0.44);
    }

    &::after {
      background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(8, 77, 126, 0.42) 100%),rgba(47, 255, 255, 0.24);
      box-shadow: inset 0 0 12px rgba(151, 200, 255, 0.44);
    }

    .button-border {
      &::before {
        background: linear-gradient(180deg, rgba(184, 238, 255, 0.24) 0%,rgba(184, 238, 255, 0) 100%),
        linear-gradient(0deg, rgba(184, 238, 255, 0.32), rgba(184, 238, 255, 0.32));
      }
    }
  }
}

.table-toggle-detail {
  background-color: rgba(#babfc7, 0.12);
}

.invoice-blank-area {
  width: 100%;
  min-width: 50px;
  background-color: rgba(#babfc7, 0.12);
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 26px 10px;
  font-size: 1.2rem;
  font-weight: 500;
  border: 2px dashed #babfc7;
  .invoice-step {
    color: #68bef3;
    text-shadow: 0em 0.01em #419fd9, 0em 0.02em #419fd9, 0em 0.02em 0.03em #419fd9,
    -0.01em 0.01em #333, -0.02em 0.02em #333, -0.03em 0.03em #333,
    -0.04em 0.04em #333, -0.01em -0.01em 0.03em #000, -0.02em -0.02em 0.03em #000,
    -0.03em -0.03em 0.03em #000;
    font-size: 1.5rem;
    margin-right: 5px;
  }
}

.detail-title {
  min-width: 250px;
}

.cart-button {
  position: relative;
  border: none;
  background: none;
  padding: 8px 10px;
  border-radius: 8px;
  // -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  // width: 100%;
  color: #fff;
  // height: 40px;
  background: #68bef3;
  &:hover {
    background:#419fd9;
  }
}

.complete-button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  color: #f4f0ff;
  text-align: center;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(26, 151, 189, 0.42) 100%),rgba(41, 223, 162, 0.4);
    box-shadow: inset 0 0 12px rgba(151, 200, 255, 0.44);
    z-index: -1;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(26, 151, 189, 0.42) 100%),rgba(41, 223, 162, 0.4);
    box-shadow: inset 0 0 12px rgba(151, 200, 255, 0.44);
    border-radius: 0.5rem;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease-in;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  .button-border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      border-radius: 0.5rem;
      padding: 1px;
      inset: 0;
      background: linear-gradient(180deg, rgba(184, 238, 255, 0.6) 0%,rgba(86, 184, 184, 0.89) 100%),
      linear-gradient(0deg, rgba(184, 238, 255, 0.6), rgba(184, 238, 255, 0.6));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* 添加标准属性 */
      -webkit-mask-composite: xor;
      mask-composite: exclude; /* 添加标准属性，`exclude` 对应 `xor` */
      pointer-events: none;
    }
  }
}

.complete-button-disable {
  background-color: #a3a3a34f;
  cursor: auto;
  &:hover {
    &::after {
      opacity: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.payment-type-radio-group {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // margin-top: 10px;

    .payment-type-radio {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;

      .radio-type {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          font-size: 14px;
          font-weight: bolder;
          margin: 0;
        }
        img {
          margin-right: 10px;
        }
      }

      input {
        appearance: none;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-animation: puls 0.7s forwards;
        animation: pulse 0.7s forwards;
        border: 1px solid #b3b3b3ad;
        background-color: #ffffff;
      }

      &:hover {
        background-color: rgba(151, 200, 255, 0.44);
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        }
        70% {
          box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    }

    .active {
      outline: 2px solid rgba(26, 151, 189, 0.42);
      background-color: rgba(151, 200, 255, 0.44);

      input {
        background-color: #419fd9;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
          background-color: #fff;
          transform: scale(1);
        }
      }
    }

    .selected {
      input {
        background-color: #419fd9;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
          background-color: #fff;
          transform: scale(1);
        }
      }
    }
}

.price-setting-radio-wrapper {
  .label {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 16px;
    margin: 5px 0;
    cursor: pointer;
    transition: .3s;
    &:hover { // &:focus-within, &:active
      background: hsla(0, 0%, 75%, 0.2);
    }
  }

  .radio-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
  }

  .radio-input:checked+.radio-design::before {
  transform: scale(0);
}

  .radio-design {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: linear-gradient(to right bottom, hsl(180, 65%, 75%), #419fd9);
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      background: hsl(0, 0%, 90%);
      transform: scale(1.1);
      transition: .3s;
    }
  }

  .label-text {
    // color: hsl(0, 0%, 60%);
    margin-left: 14px;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 500;
    transition: .3s;
  }

}

.link-card {
  padding: 30px;
  border: 1px solid #d5d2dc1a;
  background-color: rgba(184, 183, 187, 0.113);
  margin-bottom: 20px;
  border-radius: 10px;
  transition: transform ease-out 200ms;
  .link-card-image {
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 10px;
  }

  .text-body-heading {
    margin-top: 5px;
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    .link-card-image {
      animation: breathe .8s linear infinite;
    }
  }
}

.button-mode {
  position: relative;
  // padding: 100px;
  height: 150px;
  border: 1px solid #d5d2dc1a;
  background-color: rgba(184, 183, 187, 0.113);
  margin-bottom: 10px;
  border-radius: 10px;
  transition: transform ease-out 200ms;
  .link-card-image {
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 10px;
  }

  .text-body-heading {
    margin-top: 5px;
    font-size: 16px;
  }
}

.button-type {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.small-button {
  position: absolute;
  width: calc(50% - 17px);
  height: calc(50% - 17px);
  border: none;
  border-radius: 10px;
  background-color: rgba(184, 183, 187, 0.278);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  .button-small-type {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .link-card-small-image {
      max-width: 30px;
      max-height: 30px;
      margin-bottom: 5px;
    }

    .text-body-small-heading {
      font-size: 14px;
    }
  }
}

/* 当鼠标悬停在大按钮上时显示小按钮 */
.button-mode:hover {
  .small-button {
    cursor: pointer;
    opacity: 1;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      .link-card-small-image {
        animation: breathe .8s linear infinite;
      }
    }
  }
  .button-type {
    opacity: 0;
  }
}

/* 这里只是简单地定义了四个小按钮的位置 */
.big-button {
  .small-button:nth-child(1) {
    top: 10px;
    left: 10px;
  }
  .small-button:nth-child(2) {
    top: 10px;
    right: 10px;
  }
  .small-button:nth-child(3) {
    bottom: 10px;
    left: 10px;
  }
  .small-button:nth-child(4) {
    bottom: 10px;
    right: 10px;
  }
}

</style>
